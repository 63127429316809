<template>
    <child-layout title="Export Logs Results">
        <ag-grid-server-side
            id="exportLogResults"
            :server-side-datasource="serverSideDatasource"
            server-side-store-type="full"
            suppress-row-click-selection="true"
            :column-defs="columnDefs"
        >
            <template #controls>
                <archive-download-button v-if="archivePath" :path="archivePath" />
            </template>
        </ag-grid-server-side>
    </child-layout>
</template>

<script>
import ChildLayout from '@/components/layout/ChildLayout'
import AgGridServerSide from '@/components/table/AgGridServerSide'
import ColumnDef from '@/helpers/ag-grid/columnDef/exportLog/results'
import { mapActions, mapGetters } from 'vuex'
import LogActions from '@/views/DataExplorer/LogActions'
import FileDownload from '@/components/table/FileDownload'
import ArchiveDownloadButton from '@/components/ArchiveDownloadButton'

export default {
    name: 'ExportLogResults',
    components: {
        ChildLayout,
        AgGridServerSide,
        // eslint-disable-next-line vue/no-unused-components
        LogActions,
        // eslint-disable-next-line vue/no-unused-components
        FileDownload,
        ArchiveDownloadButton,
    },
    data: () => ({
        columnDefs: ColumnDef,
    }),
    computed: {
        ...mapGetters({
            fetching: 'exportLogResults/getFetching',
            items: 'exportLogResults/getItems',
            lea: 'getDistrictLea',
        }),
        batchId() {
            return this.$route.params.batchId
        },
        archivePath() {
            return this.items.length > 0
                ? this.items.find((f) => f.filePath && f.filePath.length > 1)?.filePath
                : null
        },
    },
    methods: {
        ...mapActions({
            fetchRows: 'exportLogResults/get',
        }),
        serverSideDatasource: function () {
            const fetch = async () => {
                const leaId = this.lea
                await this.fetchRows({ lea: leaId, batchId: this.batchId })

                return this.items
            }

            return {
                getRows: async function (params) {
                    const data = await fetch()

                    params.successCallback(data, data.length)
                },
            }
        },
    },
}
</script>

<style scoped></style>
