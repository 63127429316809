import { displayDateOnly, displayTime } from '@/helpers/ag-grid/cellFormats'

import { timeHeader } from '@/helpers/ag-grid/headerFormats'

export default [
    {
        headerName: 'Batch ID',
        field: 'batchId',
        hide: true,
    },
    {
        headerName: 'ETL Status ID',
        field: 'etlStatusId',
        hide: true,
    },
    {
        headerName: 'Date',
        field: 'startTime', //using startTime because the DB value for date doesn't have a timezone
        width: 140,
        valueFormatter: (params) => displayDateOnly(params.value),
    },
    {
        headerName: timeHeader('Start Time'),
        field: 'startTime',
        valueFormatter: (params) => displayTime(params.value),
        width: 175,
    },
    {
        headerName: timeHeader('End Time'),
        field: 'endTime',
        valueFormatter: (params) => displayTime(params.value),
        width: 175,
    },
    {
        headerName: 'Component Name',
        field: 'componentName',
        width: 185,
    },
    {
        headerName: 'File Name',
        field: 'fileName',
        width: 285,
        cellRendererFramework: 'FileDownload',
    },
    {
        headerName: 'File Path',
        field: 'filePath',
        width: 285,
    },
    {
        headerName: 'School Year',
        field: 'schoolYear',
        width: 150,
    },
    {
        headerName: 'Run Time (sec)',
        field: 'runTimeInSeconds',
        width: 165,
    },
    {
        headerName: 'Errors',
        cellRendererFramework: 'RowButton',
        cellRendererParams: {
            buttonParams: {
                routerLinkIds: ['batchId', 'etlStatusId'],
                routeName: 'export-log-details',
                color: 'error',
                field: 'messageType',
                fieldValue: 'Error',
            },
        },
        filter: 'agNumberColumnFilter',
        filterParams: {
            filterOptions: ['greaterThan'],
            defaultOption: 'greaterThan',
        },
        field: 'errors',
        width: 140,
    },
    {
        headerName: 'Warnings',
        cellRendererFramework: 'RowButton',
        cellRendererParams: {
            buttonParams: {
                routerLinkIds: ['batchId', 'etlStatusId'],
                routeName: 'export-log-details',
                color: 'warning',
                field: 'messageType',
                fieldValue: 'Warning',
            },
        },
        filter: 'agNumberColumnFilter',
        filterParams: {
            filterOptions: ['greaterThan'],
            defaultOption: 'greaterThan',
        },
        field: 'warnings',
        width: 140,
    },
    {
        headerName: 'Total',
        field: 'recordsProcessed',
    },
    {
        headerName: 'Status',
        field: 'recentStatus',
    },
    {
        headerName: 'Action',
        cellRendererFramework: 'LogActions',
        cellRendererParams: {
            linkButtons: [
                {
                    routerLinkIds: ['batchId', 'etlStatusId'],
                    routeName: 'export-log-details',
                    btnText: 'Details',
                    role: 'ROLE_EXPORT_LOG_VIEW',
                    icon: 'fa fa-eye',
                },
            ],
        },
        filter: false,
        width: 100,
        pinned: 'right',
        lockPinned: true,
        lockVisible: true,
        visible: true,
        lockPosition: true,
        hide: false,
        suppressMenu: true,
        sortable: false,
    },
]
